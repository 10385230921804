.profile-container {
    width: 50px; /* Adjust width and height as needed */
    height: 50px;
    border-radius: 50%; /* Makes the div rounded */
    overflow: hidden; /* Clips the image to the rounded shape */
    border: 2px solid #000; /* Optional: Add a border around the profile photo */
    flex-direction:row;
    margin-right: 20px;
  }

  .profile-photo {
    width: 100%; /* Ensures the image fills the rounded container */
    height: 100% /* Allows the image to maintain its aspect ratio */
  }
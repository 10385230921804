.ActiveLight {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .colored-circle {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: black;
    height: 20px;
    width: 20px;
  }
  
  .red {
    background-color: red;
  }
  
  .green {
    background-color: green;
  }
.container-mobile {
    max-width: 500px;
    padding: 0;
}

.div-wrap {
    position: relative;
}

.box-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 15%;
    border-bottom-right-radius: 15%;
    margin-bottom: -100px;
    background-image: url(images/Signup3.jpg);
}

.logo img {
    width: 100px;
}

.form-div {
    padding: 40px;
    margin: 0px 30px;
    border-radius: 40px;
    background-color: white;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 6px 5px 2px rgba(219, 219, 219, 1);
    -moz-box-shadow: 1px 6px 5px 2px rgba(219, 219, 219, 1);
    box-shadow: 1px 6px 5px 2px rgba(219, 219, 219, 1);
}

.orange {
    color: white;
    background-color: #f95d2d;
    width: 100%;
    border: 1px solid #f95d2d;
    padding: 10px;
}

.orange:hover {
    color: white;
    background-color: #b54d2c;
}

.form-buttons {
    border-radius: 5px;
    display: flex;

}

.form-control {
    outline: none;
    border: none;
    border-bottom: 1px solid #dfdfdf;
}

.form-floating>label {
    color: #8d8d8d;
}

.form-control:focus-visible {
    box-shadow: 0 0 0 0.25rem #f95d2d3b;
    width: 100%;
    border: none !important;
}

.border-grey {
    border: 1px solid #dfdfdf;
    background-color: #efefef;
}


.btn-orange {
    background-color: #f95d2d;
    color: white;
    padding: 10px;
}

.btn-orange:hover {
    color: white;
    background-color: #b54d2c;
}

.btn-grey {
    color: #f95d2d;
    border: none;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-grey a {
    text-decoration: none;
    color: #f95d2d;
    width: 100%;
}

.password-eye {
    background-color: transparent;
    border: none;
}

.password-box {
    border-bottom: 1px solid #dfdfdf;
}


.forgot-password-link {
    text-align: end;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
}

.forgot-password-link a {
    text-decoration: none;
    color: black;
}

.bi {
    display: flex;
    align-items: center;
}

.greet {
    display: block;
    font-size: 20px;
    color: #8d8d8d;
    font-weight: 700;
}

.reset-span {
    display: block;
    font-size: 11px;
    color: #8d8d8d;
    font-weight: 700;
}

.agree-terms {
    font-size: 12px;
    text-align: center;
    margin-bottom: 40px;
    color: #8d8d8d;
    padding: 0px 10px;
}


@media (max-width:330px) {
    .form-buttons {
        display: block;
    }

    .border-grey {
        border: none;
        background-color: white;
    }

    .btn-orange,
    .btn-grey {
        margin-top: 8px;
        border: 1px solid #dfdfdf;
    }

}
.topAlert {
    background-color: #2c2c2c;
    height: 12vh;
    color: #f0f0f0;
    font-size: xx-large;
}


.footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color:#343a40;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #f0f0f0;
    justify-content: center;
    text-align: center;
  }